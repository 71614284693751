<!--hero section start-->
<section class="hero-section ptb-100 background-img3 full-screen"
style="background: url('../../../assets/img/hero-bg-1.jpg')no-repeat center center / cover">
<div class="container">
<div class="row align-items-center justify-content-center">
   <div class="col-md-9 col-lg-7">
       <div class="hero-content-left text-white text-center">
           <h1 class="text-white">Thank You !</h1>

           <p class="lead">We will be in touch shortly. If you have any urgent issues free to give us a call.</p>

           <div class="mb-5">
               <h5 class="mb-0 text-white"> <span class="ti-mobile mr-2 align-items-center"></span> +234 903 140 1731</h5>
           </div>
           
       </div>
   </div>
</div>
</div>
</section>
<!--hero section end-->