import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BreadcrumbService, Breadcrumb } from 'angular-crumbs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private titleServices: Title, private breadcrumbService: BreadcrumbService){
  }

  ngOnInit(): void {
    this.breadcrumbService.breadcrumbChanged.subscribe( crumbs => {
      this.titleServices.setTitle(this.createTitle(crumbs));
    })
  }

  private createTitle(routesCollection: Breadcrumb[]) {
    const title = 'Spangler Market - Creating a better future for entrepreneurs';
    const titles = routesCollection.filter((route) => route.displayName);

    if (!title.length) { return title; }

    const routeTitle = this.titleToString(titles);
    return `${routeTitle} ${title}`;
  }

  private titleToString(titles) {
    return titles.reduce((prev, curr) => {
      return `${curr.displayName} - ${prev}`;
    }, '');
  }



}
