 <!--our video promo section start-->
 <section id="download" class="video-promo ptb-100">
    <div class="container">
        <div class="row align-items-center justify-content-between">
            <div class="col-md-7">
                <div class="download-content">
                    <!-- <h2>Download App Manage Your Business More Faster</h2> -->
                    <p>We recognize that each time you log-in to the Trackhub app, you are putting your trust in us- not only to deliver your items successfully, but also to ensure that the quality of the delivery experience is repeatable. Building trust is a core part of the superior on-demand experience the Trackhub platform espouses.</p>
                    <p>We sign-up delivery merchants through a rigorous enrollment process and ensure that registered delivery merchants continuously meet established quality standards. Our field inspection team also ensures that delivery riders are properly trained by their companies and that delivery bikes are fit for service. Users of the Trackhub platform are guaranteed quality delivery experience through our community of delivery merchants…Enjoy the experience and return for more.</p>
                    <p>We recognize that each time you log-in to the Trackhub app, you are putting your trust in us- not only to deliver your items successfully, but also to ensure that the quality of the delivery experience is repeatable. Building trust is a core part of the superior on-demand experience the Trackhub platform espouses.</p>
                    <p>We sign-up delivery merchants through a rigorous enrolment process and ensure that registered delivery merchants continuously meet established quality standards. Our field inspection team also ensures that delivery riders are properly trained by their companies and that delivery bikes are fit for service. Users of the Trackhub platform are guaranteed quality delivery experience through our community of delivery merchants…Enjoy the experience and return for more.</p>
                </div>
            </div>
            <div class="col-md-5">
                <div class="download-img mt-lg-5 mt-md-5 mt-sm-0">
                    <img src="../../../assets/img/image-14.png" alt="download" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</section>
<!--our video promo section end-->