import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tlayout',
  templateUrl: './tlayout.component.html',
  styleUrls: ['./tlayout.component.scss']
})
export class TlayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
