<!--header section start-->
<header class="header">
    <!--start navbar-->
    <nav class="navbar navbar-expand-lg fixed-top bg-transparent">
        <div class="container">
            <a class="navbar-brand" href="/"><img src="assets/img/logo3.png" width="180px" alt="logo"
                                                           class="img-fluid"></a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="ti-menu"></span>
            </button>

            <div class="collapse navbar-collapse main-menu" id="navbarSupportedContent">
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item">
                        <a href="/" class="nav-link page-scroll">
                            Home
                        </a>
                    </li>
                    <!-- <li class="nav-item">
                        <a class="nav-link page-scroll" href="/about">About Us</a>
                    </li> -->
                    <li class="nav-item">
                        <a class="nav-link page-scroll" href="/ads">Ads</a>
                    </li>
                    <!-- <li class="nav-item">
                        <a class="nav-link page-scroll" href="/how-it-works">How It Works</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link page-scroll" href="/faq">FAQ</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link page-scroll" href="/contact">Contact Us</a>
                    </li> -->

                </ul>
                <a class="navbar-brand discover-btn" href="https://spanglermarket.com/become-a-vendor/" target="_blank">Register as a Seller</a>
            </div>
        </div>
    </nav>
    <!--end navbar-->
</header>
<!--header section end-->