<section id="features" class="feature-section ptb-100 bgimg">
    <div class="container">
        <div class="row align-items-center justify-content-between">
            <div class="col-md-4">
                <div class="download-img">
                    <!-- <img src="../../../assets/img/screen3.jpg" alt="download" class="img-fluid"> -->
                </div>
            </div>
            <div class="col-md-6 wow slideInLeft" data-wow-delay="0.20s" style="visibility: visible; animation-delay: 0.20s; animation-name: slideInUp;">
                <div class="feature-contents section-heading">
                    <p class="ptop">Our Company</p>
                    <h3 style="margin-bottom: 0px; color: #15537c;">Get to Know Us</h3>
                    <hr class="hrc">

                    <p class="jus">Trackhub is a logistics technology business with defined service offerings that include on-demand and scheduled last-mile deliveries for end-users via the Trackhub platform - a web and mobile application which aggregates delivery merchant platforms, retail outlets and users into a hub.</p>

                    <p class="jus">With Trackhub, users can connect seamlessly to dispatch riders, receive notifications on pick-ups/delivery and provide ratings/reviews for delivery service received while delivery merchants can monitor the dispatch activities of their riders, address user requests and complaints.</p>

                    <div class="feature-content-wrap">
                        <ul class="nav nav-tabs feature-tab" data-tabs="tabs">
                            <li class="nav-item">
                                <a class="nav-link h6 active" href="#tab6-2" data-toggle="tab">
                                    <!-- <span class="ti-vector"></span> -->
                                    Our Mission
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link h6" href="#tab6-1" data-toggle="tab">
                                    <!-- <span class="ti-palette"></span> -->
                                    Our Vision
                                </a>
                            </li>
                           
                            <li class="nav-item">
                                <a class="nav-link h6" href="#tab6-3" data-toggle="tab">
                                    <!-- <span class="ti-bar-chart"></span> -->
                                    Our Strengths
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content feature-tab-content">
                            <div class="tab-pane active" id="tab6-2">
                                <p class="jus">To solve the last mile logistics problem in Africa using enabling technology and through the curation of a common set of quality standards that work.</p>
                            </div>
                            <div class="tab-pane" id="tab6-1">
                                <p class="jus">An African logistics ecosystem where last mile delivery is seamless.</p>
                            </div>
                            
                            <div class="tab-pane" id="tab6-3">
                                <p class="jus">Seamless technology, Superior service delivery, Strategic partnerships.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>