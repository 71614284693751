<section class="download-section pt-100 mtb-100 background-img2">
    <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-md-9 col-lg-7">
            <div class="hero-content-left text-center">
              <!-- <h1 class="">Attention!</h1> -->
    
              <p class="lead">
                Kindly send your proof of payment and advert content via WhatsApp
              </p>
    
              <div class="mb-5">
                <h5 class="mb-0">
                 <a href="https://api.whatsapp.com/send?phone=23463684073&text=Hi%20Product%20Manager%2C%20I%20was%20asked%20to%20chat%20you%20up%20to%20process%20my%20advert.">   
                  <span class="ti-mobile mr-2 align-items-center"></span>
                  +2348063684073
                </a>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
</section>


<section>
    <div class="container pdtmj">

        <div class="row text-center align-items-end">
            <!-- Pricing Table-->
            <div class="col-lg-4 mb-5 mb-lg-0">
                <div class="bg-white p-5 rounded-lg shadow">
                    <h1 class="h6 text-uppercase font-weight-bold mb-4">Silver</h1>
                    <h2 class="h1 font-weight-bold">₦ 1,000</h2>

                    <div class="custom-separator my-4 mx-auto bg-primary"></div>

                    <ul class="list-unstyled my-5 text-small text-left">
                        <li class="mb-3">
                            <i class="fa fa-check mr-2 text-primary"></i> SMVS Post
                        </li>
                        <li class="mb-3">
                            <i class="fa fa-check mr-2 text-primary"></i> SMVS MAX Post
                        </li>
                        <li class="mb-3 text-muted">
                            <i class="fa fa-times mr-2"></i>
                            <del>All WhatsApp Groups Post</del>
                        </li>
                        <li class="mb-3 text-muted">
                            <i class="fa fa-times mr-2"></i>
                            <del>48hrs Facebook Announcement Post</del>
                        </li>
                        <li class="mb-3 text-muted">
                            <i class="fa fa-times mr-2"></i>
                            <del>Spangler Post Mention</del>
                        </li>
                        <li class="mb-3 text-muted">
                            <i class="fa fa-times mr-2"></i>
                            <del>Instagram Post</del>
                        </li>
                        <li class="mb-3 text-muted">
                            <i class="fa fa-times mr-2"></i>
                            <del>1 day Facebook Live Video</del>
                        </li>
                        <li class="mb-3 text-muted">
                            <i class="fa fa-times mr-2"></i>
                            <del>1 Day Facebook Live Video Announcement</del>
                        </li>
                    </ul>
                    <a href="https://pay.collect.africa/xr5s3z1xgp" class="btn btn-primary btn-block p-2 shadow rounded-pill">Subscribe</a>
                </div>
            </div>
            <!-- END -->

            <!-- Pricing Table-->
            <div class="col-lg-4 mb-5 mb-lg-0">
                <div class="bg-white p-5 rounded-lg shadow">
                    <h1 class="h6 text-uppercase font-weight-bold mb-4">Diamond</h1>
                    <h2 class="h1 font-weight-bold">₦ 3,000</h2>

                    <div class="custom-separator my-4 mx-auto bg-primary"></div>

                    <ul class="list-unstyled my-5 text-small text-left">
                        <li class="mb-3">
                            <i class="fa fa-check mr-2 text-primary"></i> SMVS Post
                        </li>
                        <li class="mb-3">
                            <i class="fa fa-check mr-2 text-primary"></i> SMVS MAX Post
                        </li>
                        <li class="mb-3">
                            <i class="fa fa-check mr-2 text-primary"></i> All WhatsApp Groups Post
                        </li>
                        <li class="mb-3 text-muted">
                            <i class="fa fa-times mr-2"></i>
                            <del>48hrs Facebook Announcement Post</del>
                        </li>
                        <li class="mb-3 text-muted">
                            <i class="fa fa-times mr-2"></i>
                            <del>Spangler Post Mention</del>
                        </li>
                        <li class="mb-3 text-muted">
                            <i class="fa fa-times mr-2"></i>
                            <del>Instagram Post</del>
                        </li>
                        <li class="mb-3 text-muted">
                            <i class="fa fa-times mr-2"></i>
                            <del>1 day Facebook Live Video</del>
                        </li>
                        <li class="mb-3 text-muted">
                            <i class="fa fa-times mr-2"></i>
                            <del>1 Day Facebook Live Video Announcement</del>
                        </li>
                    </ul>
                    <a href="https://pay.collect.africa/wnvhhlublh" class="btn btn-primary btn-block p-2 shadow rounded-pill">Subscribe</a>
                </div>
            </div>
            <!-- END -->

            <!-- Pricing Table-->
            <div class="col-lg-4 mb-5 mb-lg-0">
                <div class="bg-white p-5 rounded-lg shadow">
                    <h1 class="h6 text-uppercase font-weight-bold mb-4">Announcement</h1>
                    <h2 class="h1 font-weight-bold">₦ 5,000</h2>

                    <div class="custom-separator my-4 mx-auto bg-primary"></div>

                    <ul class="list-unstyled my-5 text-small text-left">
                        <li class="mb-3">
                            <i class="fa fa-check mr-2 text-primary"></i> SMVS Post
                        </li>
                        <li class="mb-3">
                            <i class="fa fa-check mr-2 text-primary"></i> SMVS MAX Post
                        </li>
                        <li class="mb-3">
                            <i class="fa fa-check mr-2 text-primary"></i> All WhatsApp Groups Post
                        </li>
                        <li class="mb-3">
                            <i class="fa fa-check mr-2 text-primary"></i> 48hrs Facebook Announcement Post
                        </li>
                        <li class="mb-3 text-muted">
                            <i class="fa fa-times mr-2"></i>
                            <del>Spangler Post Mention</del>
                        </li>
                        <li class="mb-3 text-muted">
                            <i class="fa fa-times mr-2"></i>
                            <del>Instagram Post</del>
                        </li>
                        <li class="mb-3 text-muted">
                            <i class="fa fa-times mr-2"></i>
                            <del>1 day Facebook Live Video</del>
                        </li>
                        <li class="mb-3 text-muted">
                            <i class="fa fa-times mr-2"></i>
                            <del>1 Day Facebook Live Video Announcement</del>
                        </li>
                    </ul>
                    <a href="https://pay.collect.africa/2cytp2nggh" class="btn btn-primary btn-block p-2 shadow rounded-pill">Subscribe</a>
                </div>
            </div>
            <!-- END -->
        </div>
    </div>
</section>

<section>
    <div class="container py-5">

        <div class="row text-center align-items-end">
            <!-- Pricing Table-->
            <div class="col-lg-4 mb-5 mb-lg-0">
                <div class="bg-white p-5 rounded-lg shadow">
                    <h1 class="h6 text-uppercase font-weight-bold mb-4">Spangler Mention</h1>
                    <h2 class="h1 font-weight-bold">₦ 10,000</h2>

                    <div class="custom-separator my-4 mx-auto bg-primary"></div>

                    <ul class="list-unstyled my-5 text-small text-left">
                        <li class="mb-3">
                            <i class="fa fa-check mr-2 text-primary"></i> SMVS Post
                        </li>
                        <li class="mb-3">
                            <i class="fa fa-check mr-2 text-primary"></i> SMVS MAX Post
                        </li>
                        <li class="mb-3">
                            <i class="fa fa-check mr-2 text-primary"></i> All WhatsApp Groups Post
                        </li>
                        <li class="mb-3">
                            <i class="fa fa-check mr-2 text-primary"></i> Spangler Post Mention
                        </li>
                        <li class="mb-3">
                            <i class="fa fa-check mr-2 text-primary"></i> Instagram Post
                        </li>
                        <li class="mb-3 text-muted">
                            <i class="fa fa-times mr-2"></i>
                            <del>48hrs Facebook Announcement Post</del>
                        </li>
                        <li class="mb-3 text-muted">
                            <i class="fa fa-times mr-2"></i>
                            <del>1 day Facebook Live Video</del>
                        </li>
                        <li class="mb-3 text-muted">
                            <i class="fa fa-times mr-2"></i>
                            <del>1 Day Facebook Live Video Announcement</del>
                        </li>
                    </ul>
                    <a href="https://pay.collect.africa/exkduayeps" class="btn btn-primary btn-block p-2 shadow rounded-pill">Subscribe</a>
                </div>
            </div>
            <!-- END -->

            <!-- Pricing Table-->
            <div class="col-lg-4 mb-5 mb-lg-0">
                <div class="bg-white p-5 rounded-lg shadow">
                    <h1 class="h6 text-uppercase font-weight-bold mb-4">Emerald</h1>
                    <h2 class="h1 font-weight-bold">₦ 15,000</h2>

                    <div class="custom-separator my-4 mx-auto bg-primary"></div>

                    <ul class="list-unstyled my-5 text-small text-left">
                        <li class="mb-3">
                            <i class="fa fa-check mr-2 text-primary"></i> SMVS Post
                        </li>
                        <li class="mb-3">
                            <i class="fa fa-check mr-2 text-primary"></i> SMVS MAX Post
                        </li>
                        <li class="mb-3">
                            <i class="fa fa-check mr-2 text-primary"></i> All WhatsApp Groups Post
                        </li>
                        <li class="mb-3">
                            <i class="fa fa-check mr-2 text-primary"></i>
                            Spangler Post Mention
                        </li>
                        <li class="mb-3">
                            <i class="fa fa-check mr-2 text-primary"></i>
                            Instagram Post
                        </li>
                        <li class="mb-3">
                            <i class="fa fa-check mr-2 text-primary"></i>
                            1 day Facebook Live Video
                        </li>
                        <li class="mb-3">
                            <i class="fa fa-check mr-2 text-primary"></i>
                            1 Day Facebook Live Video Announcement
                        </li>
                        <li class="mb-3 text-muted">
                            <i class="fa fa-times mr-2"></i>
                            <del>48hrs Facebook Announcement Post</del>
                        </li>
                    </ul>
                    <a href="https://pay.collect.africa/4kunvg2y8n" class="btn btn-primary btn-block p-2 shadow rounded-pill">Subscribe</a>
                </div>
            </div>
            <!-- END -->

            <!-- Pricing Table-->
            <div class="col-lg-4 mb-5 mb-lg-0">
                <div class="bg-white p-5 rounded-lg shadow">
                    <h1 class="h6 text-uppercase font-weight-bold mb-4">SME Pack</h1>
                    <h2 class="h1 font-weight-bold">₦ 50,000<span class="text-small font-weight-normal ml-2">/ Month</span></h2>

                    <div class="custom-separator my-4 mx-auto bg-primary"></div>

                    <ul class="list-unstyled my-5 text-small text-left">
                        <li class="mb-3">
                            <i class="fa fa-check mr-2 text-primary"></i> 1 Instagram Story Posts / Week
                        </li>
                        <li class="mb-3">
                            <i class="fa fa-check mr-2 text-primary"></i> 1 Posts on Instagram Page / Week
                        </li>
                        <li class="mb-3">
                            <i class="fa fa-check mr-2 text-primary"></i> 1 WhatsApp Status Posts ?/ Week
                        </li>
                        <li class="mb-3">
                            <i class="fa fa-check mr-2 text-primary"></i> 1 Posts on all Spangler Facebook Group / Week
                        </li>
                        <li class="mb-3">
                            <i class="fa fa-check mr-2 text-primary"></i> 1 Posts on all Spangler WhatsApp Group / Week
                        </li>
                        <li class="mb-3">
                            <i class="fa fa-check mr-2 text-primary"></i> 2 Videos of Spangler talking about your product
                        </li>
                        <li class="mb-3">
                            <i class="fa fa-check mr-2 text-primary"></i> 2 Designs with Face of Spangler
                        </li>
                        
                    </ul>
                    <a href="https://pay.collect.africa/e8asmu4fv2" class="btn btn-primary btn-block p-2 shadow rounded-pill">Subscribe</a>
                </div>
            </div>
            <!-- END -->
        </div>
    </div>
</section>

<section>
    <div class="container py-5">

        <div class="row text-center align-items-end">
            <!-- Pricing Table-->
            <div class="col-lg-4 mb-5 mb-lg-0">
                <div class="bg-white p-5 rounded-lg shadow">
                    <h1 class="h6 text-uppercase font-weight-bold mb-4">App Featured Banner</h1>
                    <h2 class="h1 font-weight-bold">₦ 10,000</h2>

                    <div class="custom-separator my-4 mx-auto bg-primary"></div>

                    <ul class="list-unstyled my-5 text-small text-left">
                        <li class="mb-3">
                            <i class="fa fa-check mr-2 text-primary"></i> Display your shop or product banner on the mobile app front page for 7 days
                        </li>
                    </ul>
                    <a href="https://pay.collect.africa/g405w1czwh" class="btn btn-primary btn-block p-2 shadow rounded-pill">Subscribe</a>
                </div>
            </div>
            <!-- END -->

            <!-- Pricing Table-->
            <div class="col-lg-4 mb-5 mb-lg-0">
                <div class="bg-white p-5 rounded-lg shadow">
                    <h1 class="h6 text-uppercase font-weight-bold mb-4">App Featured Product</h1>
                    <h2 class="h1 font-weight-bold">₦ 5,000</h2>

                    <div class="custom-separator my-4 mx-auto bg-primary"></div>

                    <ul class="list-unstyled my-5 text-small text-left">
                        <li class="mb-3">
                            <i class="fa fa-check mr-2 text-primary"></i> Display your product on the mobile app front page for 7 days
                        </li>
                    </ul>
                    <a href="https://pay.collect.africa/ug45kb0loo" class="btn btn-primary btn-block p-2 shadow rounded-pill">Subscribe</a>
                </div>
            </div>
            <!-- END -->

            <!-- Pricing Table-->
            <div class="col-lg-4 mb-5 mb-lg-0">
                <div class="bg-white p-5 rounded-lg shadow">
                    <h1 class="h6 text-uppercase font-weight-bold mb-4">App Notification</h1>
                    <h2 class="h1 font-weight-bold">₦ 10,000</h2>

                    <div class="custom-separator my-4 mx-auto bg-primary"></div>

                    <ul class="list-unstyled my-5 text-small text-left">
                        <li class="mb-3">
                            <i class="fa fa-check mr-2 text-primary"></i> Send your product or offer as a push notification directly to all Spangler Market app holder.
                        </li>
                    </ul>
                    <a href="https://pay.collect.africa/16hmwfuahe" class="btn btn-primary btn-block p-2 shadow rounded-pill">Subscribe</a>
                </div>
            </div>
            <!-- END -->

            
        </div>
    </div>
</section>