import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-layouts',
  templateUrl: './new-layouts.component.html',
  styleUrls: ['./new-layouts.component.scss']
})
export class NewLayoutsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
