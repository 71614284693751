<section class="hero-section ptb-100 background-img3" style="background: url('assets/img/hero-bg-1.jpg')no-repeat center center / cover">
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="col-md-9 col-lg-7">
                <div class="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
                    <breadcrumb #parent>
                        <div class="custom-breadcrumb h1">
                            <ol class="breadcrumb d-inline-block bg-transparent list-inline py-0" *ngFor="let route of parent.breadcrumbs">
                                <li class="list-inline-item breadcrumb-item h1"><a mat-button *ngIf="route.terminal">{{ route.displayName }}</a></li>
                            </ol>
                        </div>
                    </breadcrumb>
                </div>
            </div>
        </div>
        <div class="row align-items-center justify-content-center">
            <div class="col-md-9 col-lg-7">
                <div class="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
                    <breadcrumb #parent>
                        <div class="custom-breadcrumb">
                            <ol class="breadcrumb d-inline-block bg-transparent list-inline py-0" *ngFor="let route of parent.breadcrumbs">
                                <li class="list-inline-item breadcrumb-item"><a routerLink="/">Home</a></li>
                                <li class="list-inline-item breadcrumb-item"><a mat-button *ngIf="route.terminal">{{ route.displayName }}</a></li>
                            </ol>
                        </div>
                    </breadcrumb>
                </div>
            </div>
        </div>
    </div>
</section>


