import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { Header2Component } from './components/header2/header2.component';
import { SubheadComponent } from './components/subhead/subhead.component';
import { NewLayoutsComponent } from './layouts/new-layouts/new-layouts.component';
import { SlayoutComponent } from './layouts/slayout/slayout.component';
import { AboutComponent } from './pages/about/about.component';
import { FaqComponent } from './pages/faq/faq.component';
import { HowItWorksComponent } from './pages/how-it-works/how-it-works.component';
import { ContactComponent } from './pages/contact/contact.component';
import {BreadcrumbModule} from 'angular-crumbs';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { MerchantComponent } from './pages/merchant/merchant.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { ReputationAndTrustComponent } from './pages/reputation-and-trust/reputation-and-trust.component';
import { Footer2Component } from './components/footer2/footer2.component';
import { MlayoutComponent } from './layouts/mlayout/mlayout.component';
import { ThankYouComponent } from './pages/thank-you/thank-you.component';
import { TlayoutComponent } from './layouts/tlayout/tlayout.component';

import { GenericService } from 'src/app/services/generic.service';
import { TokenInterceptorService } from 'src/app/services/token-interceptor.service';
import { AuthService } from 'src/app/services/auth.service';
import { AuthGuard } from 'src/app/services/auth.guard';
import { DtService } from 'src/app/services/dt.service';
import { MySharedEvent } from 'src/app/services/shared-events.service';

import { FormsModule } from '@angular/forms';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AdsComponent } from './pages/ads/ads.component';
import { SuccessComponent } from './pages/success/success.component';



@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    FooterComponent,
    HeaderComponent,
    Header2Component,
    SubheadComponent,
    NewLayoutsComponent,
    SlayoutComponent,
    AboutComponent,
    FaqComponent,
    HowItWorksComponent,
    ContactComponent,
    MerchantComponent,
    ThankYouComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    ReputationAndTrustComponent,
    Footer2Component,
    MlayoutComponent,
    TlayoutComponent,
    AdsComponent,
    SuccessComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BreadcrumbModule,
    CarouselModule
  ],
  providers: [
    GenericService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService,
            multi: true,
        },
        AuthService,
        AuthGuard,
        DtService,
        MySharedEvent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
