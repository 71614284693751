import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Router } from '@angular/router'
import { API_URL } from './app-config';
//import 'rxjs/add/operator/map';

@Injectable()
export class AuthService {

  private _registerUrl = API_URL+"/register";
  private _loginApplicantUrl = API_URL+"applicant-login";
  private _loginUrl = API_URL+"login";
  private _menuUrl = API_URL+"menu";

  constructor(private http: HttpClient, private _router: Router) { }

  registerUser(user) {
    return this.http.post<any>(this._registerUrl, JSON.stringify(user));
  }

  loginUser(user) {
    return this.http.post<any>(this._loginUrl, JSON.stringify(user));
  }

  loginApplicant(user) {
    return this.http.post<any>(this._loginApplicantUrl, JSON.stringify(user));
  }

  menu() {
    return this.http.post<any>(this._menuUrl, null);
  }

  logoutUser() {
    localStorage.removeItem('token');
    localStorage.removeItem("fullname");
    localStorage.removeItem("applicantId");
    localStorage.setItem("isLoggedIn", '0');
    this._router.navigate(['/recruitment/sign-in']);
    // window.location.replace("/recruitment/sign-in");
  }

  getToken() {
    // console.log(localStorage.getItem('token'))
    return localStorage.getItem('token')||"";
  }

  getUserInfo() {
    return localStorage.getItem('fullname')||"";
  }

  getApplicantId() {
    return localStorage.getItem('applicantId')||"";
  }

  // loggedIn() {
  //   return !!localStorage.getItem('token');
  // }

  loggedIn() {
    return localStorage.getItem('isLoggedIn');
  }
}
