<!--hero section start-->
<section class="hero-section pt-100 background-img secbg">
    <div class="container">
        <div class="row align-items-center justify-content-between">
            <div class="col-md-6 col-lg-6 wow slideInLeft" data-wow-delay="0.20s"
                style="visibility: visible; animation-delay: 0.20s; animation-name: slideInLeft;">
                <div class="hero-content-left text-white mt-5">
                    <h1 class="h1c">Creating a better <span class="smart">future</span> for entrepreneurs.</h1>

                    <p class="hero-text">Spangler Market is an online retail service based in Nigeria owned by Spangler
                        Integrated Service Ltd. It is made up of small businesses in Nigeria and other locations, such
                        as United Kingdom, USA, France, Zambia, Ghana, Cotonou, the Benin Republic that offer products
                        to international online buyers.
                    </p>

                    <!-- <a href="/about" class="btn-red scrool">Know more</a> -->
                    <!-- <a href="#" target="_blank" class="hero-download-store"><img src="assets/img/google.png"
                            alt="pic"></a>
                    <a href="#" class="hero-download-store"><img src="assets/img/apple.png" alt="pic"></a> -->


                </div>
            </div>
            <div class="col-md-6 col-lg-5 wow slideInLeft" data-wow-delay="0.20s"
                style="visibility: visible; animation-delay: 0.20s; animation-name: slideInRight;">
                <!-- <div class="hero-animation-img">
                    <img src="assets/img/image-14.png" alt="img" class="img-fluid">
                </div> -->
            </div>
        </div>
        <!--counter start-->
        <div class="row">
            <ul class="list-inline counter-wrap">
                <li class="list-inline-item">
                    <div class="single-counter text-center">
                        <span>400000</span>
                        <h6 class="wc">Seller & Buyers</h6>
                    </div>
                </li>
                <li class="list-inline-item">
                    <div class="single-counter text-center">
                        <span>8</span>
                        <h6 class="wc">Plaforms</h6>
                    </div>
                </li>
                <li class="list-inline-item">
                    <div class="single-counter text-center">
                        <span>60</span>
                        <h6 class="wc">Team Members</h6>
                    </div>
                </li>
                <li class="list-inline-item">
                    <div class="single-counter text-center">
                        <span>8</span>
                        <h6 class="wc">Countries</h6>
                    </div>
                </li>
            </ul>
        </div>
        <!--counter end-->
    </div>
</section>
<!--hero section end-->



<section class="work-category pad-tb tilt3d">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 v-center">
                <div class="common-heading text-l">
                    <!-- <span>Explore our portfolio</span> -->
                    <h2>Explore Our Portfolio</h2>
                    <p>We are building and operationally developing high potential platforms across various verticals.
                    </p>
                </div>
            </div>
            <div class="col-lg-8">
                <div class="work-card-set">
                <a href="https://app.spanglermarket.com/" class="linktx">
                    <div class="icon-set wow fadeIn" data-wow-delay=".2s"
                        style="visibility: visible; animation-delay: 0.2s; animation-name: fadeIn;">
                        <div class="work-card cd1">
                            <div class="icon-bg"><img src="../../../assets/img/icons/icon-3.png" alt="Industries"></div>
                            <p>Spangler E-commerce</p>
                        </div>
                    </div>
                </a>
                <a href="https://verify.spanglermarket.com/" class="linktx">
                    <div class="icon-set wow fadeIn" data-wow-delay=".4s"
                        style="visibility: visible; animation-delay: 0.4s; animation-name: fadeIn;">
                        <div class="work-card cd2">
                            <div class="icon-bg"><img src="../../../assets/img/icons/verification.png" alt="Industries">
                            </div>
                            <p>Spangler Verification</p>
                        </div>
                    </div>
                </a>
                <a href="https://startup.spanglermarket.com/" class="linktx">
                    <div class="icon-set wow fadeIn" data-wow-delay=".6s"
                        style="visibility: visible; animation-delay: 0.6s; animation-name: fadeIn;">
                        <div class="work-card cd3">
                            <div class="icon-bg"><img src="../../../assets/img/icons/pitch.png" alt="Industries"></div>
                            <p>Biz Pitch Competition</p>
                        </div>
                    </div>
                </a>
                    <div class="icon-set wow fadeIn" data-wow-delay=".8s"
                        style="visibility: visible; animation-delay: 0.8s; animation-name: fadeIn;">
                        <div class="work-card cd4">
                            <div class="icon-bg"><img src="../../../assets/img/icons/baby.png" alt="Industries"></div>
                            <p>Baby Competition</p>
                        </div>
                    </div>
                    <div class="icon-set wow fadeIn" data-wow-delay="1s"
                        style="visibility: visible; animation-delay: 1s; animation-name: fadeIn;">
                        <div class="work-card cd5">
                            <div class="icon-bg"><img src="../../../assets/img/icons/talent.png" alt="Industries"></div>
                            <p>Spangler Got Talent</p>
                        </div>
                    </div>
                <a href="https://spanglermarket.com/ads" class="linktx">
                    <div class="icon-set wow fadeIn" data-wow-delay="1.2s"
                        style="visibility: visible; animation-delay: 1.2s; animation-name: fadeIn;">
                        <div class="work-card cd6">
                            <div class="icon-bg"><img src="../../../assets/img/icons/icon-2.png" alt="Industries"></div>
                            <p>Spangler Ads</p>
                        </div>
                    </div>
                </a>
                    <div class="icon-set wow fadeIn" data-wow-delay="1.4s"
                        style="visibility: visible; animation-delay: 1.4s; animation-name: fadeIn;">
                        <div class="work-card cd7">
                            <div class="icon-bg"><img src="../../../assets/img/icons/charity.png" alt="Industries">
                            </div>
                            <p>Spangler Charity</p>
                        </div>
                    </div>
                    <div class="icon-set wow fadeIn" data-wow-delay="1.6s"
                        style="visibility: visible; animation-delay: 1.6s; animation-name: fadeIn;">
                        <div class="work-card cd8">
                            <div class="icon-bg"><img src="../../../assets/img/icons/icon-8.png" alt="Industries"></div>
                            <p>Spangler Magazine</p>
                        </div>
                    </div>
                    <!-- <div class="icon-set wow fadeIn" data-wow-delay="1.8s"
                        style="visibility: visible; animation-delay: 1.8s; animation-name: fadeIn;">
                        <div class="work-card cd9">
                            <div class="icon-bg"><img
                                    src="https://rajeshdoot.com/niwax-demos/html/images/icons/icon-9.png"
                                    alt="Industries"></div>
                            <p>Health Service</p>
                        </div>
                    </div> -->
                    <!-- <div class="icon-set wow fadeIn" data-wow-delay="2s"
                        style="visibility: visible; animation-delay: 2s; animation-name: fadeIn;">
                        <div class="work-card cd10">
                            <div class="icon-bg"><img
                                    src="https://rajeshdoot.com/niwax-demos/html/images/icons/icon-10.png"
                                    alt="Industries"></div>
                            <p>Event &amp; Ticket</p>
                        </div>
                    </div> -->
                    <!-- <div class="icon-set wow fadeIn" data-wow-delay="2.2s"
                        style="visibility: visible; animation-delay: 2.2s; animation-name: fadeIn;">
                        <div class="work-card cd11">
                            <div class="icon-bg"><img
                                    src="https://rajeshdoot.com/niwax-demos/html/images/icons/icon-11.png"
                                    alt="Industries"></div>
                            <p>Restaurant Service</p>
                        </div>
                    </div> -->
                    <!-- <div class="icon-set wow fadeIn" data-wow-delay="2.4s"
                        style="visibility: visible; animation-delay: 2.4s; animation-name: fadeIn;">
                        <div class="work-card cd12">
                            <div class="icon-bg"><img
                                    src="https://rajeshdoot.com/niwax-demos/html/images/icons/icon-12.png"
                                    alt="Industries"></div>
                            <p>Business Consultant</p>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section>



<!--overflow block start-->
<div class="overflow-hidden">
    <!--about us section start-->
    <section id="about" class="about-us ptb-100">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-md-7">
                    <img src="../../../assets/img/built.png">
                    <!-- <div class="about-content-right">

                    </div> -->
                </div>
                <div class="col-md-5 wow slideInLeft" data-wow-delay="0.20s"
                    style="visibility: visible; animation-delay: 0.20s; animation-name: slideInRight;">
                    <p class="ptop">Our Company</p>
                    <h3 style="margin-bottom: 0px; color: #000000;">Built to Build Small Businesses</h3>
                    <hr class="hrc">

                    <p class="jus">Spangler fuels thousands of small businesses worldwide with products and technology
                        to vitalize their online presence. Every decision we make comes back to finding new ways to give
                        small business owners the tools they need to get online and grow. That unwavering focus is
                        what’s helped us grow to over 400,000 Sellers & Buyers around the world. And we’re just getting
                        started.</p>

                    <p class="jus">Our Entrepreneurs are your neighborhood foodstuff sellers, make-up artists, skin
                        therapist, fashion designers, plumbers, graphic artists, food truckers, and all the small
                        business owners that breathe life into our economy and enrich our communities.</p>

                    <!-- <a routerLink="/about" class="btn-red scrool">Know More</a> -->


                </div>
            </div>
        </div>
    </section>
    <!--about us section end-->
</div>
<!--overflow block end-->

<!--features section start-->
<div id="features" class="feature-section secbg2">
    <div class="container">

        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="section-heading text-center mb-5 wow slideInLeft" data-wow-delay="0.20s"
                    style="visibility: visible; animation-delay: 0.20s; animation-name: zoomInUp;">
                    <h2 class="secht">Spangler Family</h2>
                    <p>We're led by a team who constantly questions thinker, and challenges to unlock great creativity around every turn.</p>
                </div>
            </div>
        </div>

        <div class="row row-grid align-items-center">
            <div class="col-lg-12 wow slideInLeft" data-wow-delay="0.20s"
                style="visibility: visible; animation-delay: 0.20s; animation-name: bounceInLeft;">
                
                <owl-carousel-o [options]="customOptions">
                    <ng-template carouselSlide>
                      <div class="teaming">
                        <img src="../../../assets/img/team/ceo.jpg" alt="Purity Abhulimhen">
                      </div>
                      <div class="teamname">
                          <h3>Purity Abhulimhen</h3>
                          <p>Chief Executive Officer</p>
                      </div>
                    </ng-template>

                    <ng-template carouselSlide>
                        <div class="teaming">
                          <img src="../../../assets/img/team/ismail.jpg" alt="Akibu Ismail">
                        </div>
                        <div class="teamname">
                            <h3>Akibu Ismail</h3>
                            <p>Chief Operating Officer</p>
                        </div>
                      </ng-template>

                    <!-- <ng-template carouselSlide>
                        <div class="teaming">
                          <img src="../../../assets/img/team/faith.jpg" alt="Asekhame Faith">
                        </div>
                        <div class="teamname">
                            <h3>Asekhame Faith</h3>
                            <p>General Manager</p>
                        </div>
                      </ng-template>

                      <ng-template carouselSlide>
                        <div class="teaming">
                          <img src="../../../assets/img/team/debby.jpg" alt="Debbie Shola">
                        </div>
                        <div class="teamname">
                            <h3>Debbie Shola</h3>
                            <p>Head of Admin</p>
                        </div>
                      </ng-template>

                      

                      <ng-template carouselSlide>
                        <div class="teaming">
                          <img src="../../../assets/img/team/Priye.jpg" alt="Priye Briggs">
                        </div>
                        <div class="teamname">
                            <h3>Priye Briggs</h3>
                            <p>Head of Customer Service</p>
                        </div>
                      </ng-template>

                      <ng-template carouselSlide>
                        <div class="teaming">
                          <img src="../../../assets/img/team/offorkihe.jpg" alt="Offorkihe Maria-Dorathy Chinezerem">
                        </div>
                        <div class="teamname">
                            <h3>Offorkihe Maria-Dorathy Chinezerem</h3>
                            <p>Head of Query</p>
                        </div>
                      </ng-template> -->

                      <ng-template carouselSlide>
                        <div class="teaming">
                          <img src="../../../assets/img/team/busayo.jpg" alt="Busayo Oyebamiji">
                        </div>
                        <div class="teamname">
                            <h3>Busayo Oyebamiji</h3>
                            <p>General Manager</p>
                        </div>
                      </ng-template>

                      <!-- <ng-template carouselSlide>
                        <div class="teaming">
                          <img src="../../../assets/img/team/Robinson.jpg" alt="Omogbai Robinson">
                        </div>
                        <div class="teamname">
                            <h3>Omogbai Robinson</h3>
                            <p>Head of Verification</p>
                        </div>
                      </ng-template> -->

                      
                    
                  </owl-carousel-o>







            </div>
            
        </div>
    </div>
</div>
<!--features section end-->

<!--about us section start-->
<!-- <section id="about" class="about-us ptb-100 gray-light-bg secbg3">
    <div class="container">
        <div class="row align-items-center justify-content-between">
            <div class="col-lg-6 wow slideInLeft" data-wow-delay="0.20s"
                style="visibility: visible; animation-delay: 0.20s; animation-name: zoomInUp;">
                <h2 class="mt-4 secht">Why Trackhub?</h2>
                <p class="mb-4 lh-190">Trackhub has been designed to enable smarter deliveries by improving the delivery
                    experience for users.</p>
                <ul class="list-unstyled">
                    <li class="py-2">
                        <div class="d-flex align-items-center">
                            <div>
                                <div class="badge badge-circle badge-primary mr-3">
                                    <span class="ti-check"></span>
                                </div>
                            </div>
                            <div>
                                <p class="mb-0">Access to multiple delivery merchants through one single user app.</p>
                            </div>
                        </div>
                    </li>
                    <li class="py-2">
                        <div class="d-flex align-items-center">
                            <div>
                                <div class="badge badge-circle badge-primary mr-3">
                                    <span class="ti-check"></span>
                                </div>
                            </div>
                            <div>
                                <p class="mb-0">Quality and reliable service.</p>
                            </div>
                        </div>
                    </li>
                    <li class="py-2">
                        <div class="d-flex align-items-center">
                            <div>
                                <div class="badge badge-circle badge-primary mr-3">
                                    <span class="ti-check"></span>
                                </div>
                            </div>
                            <div>
                                <p class="mb-0">Simple, easy and secure payments.</p>
                            </div>
                        </div>
                    </li>
                    <li class="py-2">
                        <div class="d-flex align-items-center">
                            <div>
                                <div class="badge badge-circle badge-primary mr-3">
                                    <span class="ti-check"></span>
                                </div>
                            </div>
                            <div>
                                <p class="mb-0">Discounted deliveries as incentives.</p>
                            </div>
                        </div>
                    </li>
                    <li class="py-2">
                        <div class="d-flex align-items-center">
                            <div>
                                <div class="badge badge-circle badge-primary mr-3">
                                    <span class="ti-check"></span>
                                </div>
                            </div>
                            <div>
                                <p class="mb-0">Real time updates and proof of delivery.</p>
                            </div>
                        </div>
                    </li>
                    <li class="py-2">
                        <div class="d-flex align-items-center">
                            <div>
                                <div class="badge badge-circle badge-primary mr-3">
                                    <span class="ti-check"></span>
                                </div>
                            </div>
                            <div>
                                <p class="mb-0">Accurate and timely information for optimized planning.</p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="col-md-5 offset-md-1 wow slideInRight" data-wow-delay="0.20s"
                style="visibility: visible; animation-delay: 0.20s; animation-name: slideInRight;">
                <div class="about-content-right">
                    <img src="assets/img/screen3.jpg" class="img-fluid appass">
                </div>
            </div>
        </div>
    </div>
</section> -->
<!--about us section end-->






<!--screenshots section start-->
<section id="screenshots" class="screenshots-section ptbapp"
    style="background: url('assets/img/bg10.jpg')no-repeat center center / cover">
    <div class="container" style="max-width: 100% !important;">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="section-heading text-center">
                    <h2 class="text-white">Testimonies</h2>
                    <p class="lead white">Appreciation and word of encouragement from sellers around the world.</p>
                </div>
            </div>
        </div>
        <div class="screen-slider-content mt-5">
            <div class="screenshot-frame"></div>
            <div class="screen-carousel owl-carousel owl-theme dot-indicator">
                <img src="assets/img/screen1.jpg" class="img-fluid" alt="screenshots">
                <img src="assets/img/screen2.jpg" class="img-fluid" alt="screenshots">
                <img src="assets/img/screen3.jpg" class="img-fluid" alt="screenshots">
                <img src="assets/img/screen4.jpg" class="img-fluid" alt="screenshots">
                <img src="assets/img/screen5.jpg" class="img-fluid" alt="screenshots">
                <img src="assets/img/screen6.jpg" class="img-fluid" alt="screenshots">
                <img src="assets/img/screen7.jpg" class="img-fluid" alt="screenshots">
                <img src="assets/img/screen8.jpg" class="img-fluid" alt="screenshots">
                <img src="assets/img/screen9.jpg" class="img-fluid" alt="screenshots">
            </div>
        </div>
    </div>
</section>
<!--screenshots section end-->


<!--download section start-->
<section class="download-section pt-100 background-img2">
    <div class="container">
        <div class="row align-items-center justify-content-between">

            <div class="col-md-7 mx-auto text-center">
                <div class="download-content text-white pb-100 txtcenter">
                    <h2 class="text-white secht">Download Spangler App</h2>
                    <p class="hero-text2">Get access to exclusive offers!</p>

                    <div class="download-btn wow slideInLeft" data-wow-delay="0.20s"
                        style="visibility: visible; animation-delay: 0.20s; animation-name: slideInUp;">
                        <a href="https://app.spanglermarket.com" class="hero-download-store"><img src="assets/img/apple.png" alt="pic"></a>

                        <a href="https://app.spanglermarket.com" target="_blank" class="hero-download-store"><img src="assets/img/google.png"
                                alt="pic"></a>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
</section>
<!--download section end-->