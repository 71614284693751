  <!--features section start-->
  <div id="features" class="feature-section secbg2">
    <div class="container">

        

        <!--feature new style start-->
        <div class="row row-grid align-items-center">
            <div class="col-lg-4 wow slideInLeft" data-wow-delay="0.20s" style="visibility: visible; animation-delay: 0.20s; animation-name: bounceInLeft;">
                <div class="d-flex align-items-start mb-5">
                    <div class="pr-4">
                        <div class="icon icon-shape icon-color-1 rounded-circle">
                            <span>1</span>
                        </div>
                    </div>
                    <div class="icon-text">
                        <h5 class="mb2px">User request</h5>
                        <p class="mb-0">User requests a delivery by inserting pickup and drop off location on the Trackhub Mobile app.</p>
                    </div>
                </div>
                <div class="d-flex align-items-start mb-5">
                    <div class="pr-4">
                        <div class="icon icon-shape icon-color-2 rounded-circle">
                            <span>2</span>
                        </div>
                    </div>
                    <div class="icon-text">
                        <h5 class="mb2px">Merchant Selection</h5>
                        <p class="mb-0">Trackhub app displays available delivery merchant around the user's location with price and merchant rating. User selects merchant of choice.</p>
                    </div>
                </div>
                <div class="d-flex align-items-start">
                    <div class="pr-4">
                        <div class="icon icon-shape icon-color-3 rounded-circle">
                            <span>3</span>
                        </div>
                    </div>
                    <div class="icon-text">
                        <h5 class="mb2px">Payment initiated</h5>
                        <p class="mb-0">User inserts payment method and an available rider under that merchant gets assigned to that user.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 wow slideInLeft" data-wow-delay="0.20s" style="visibility: visible; animation-delay: 0.20s; animation-name: slideInUp;">
                <div class="position-relative" style="z-index: 10;">
                    <img alt="Image placeholder" src="assets/img/image-10.png" class="img-center img-fluid appass">
                </div>
            </div>
            <div class="col-lg-4 wow slideInLeft" data-wow-delay="0.20s" style="visibility: visible; animation-delay: 0.20s; animation-name: bounceInRight;">
                <div class="d-flex align-items-start mb-5">
                    <div class="pr-4">
                        <div class="icon icon-shape icon-color-4 rounded-circle">
                            <span>4</span>
                        </div>
                    </div>
                    <div class="icon-text">
                        <h5 class="mb2px">Dispatch pick-up</h5>
                        <p class="mb-0">The dispatch rider receives push notification with dispatch details and heads for the pickup.</p>
                    </div>
                </div>
                <div class="d-flex align-items-start mb-5">
                    <div class="pr-4">
                        <div class="icon icon-shape icon-color-5 rounded-circle">
                            <span>5</span>
                        </div>
                    </div>
                    <div class="icon-text">
                        <h5 class="mb2px">Package delivery</h5>
                        <p class="mb-0">The rider delivers the package as scheuled.</p>
                    </div>
                </div>
                <div class="d-flex align-items-start">
                    <div class="pr-4">
                        <div class="icon icon-shape icon-color-6 rounded-circle">
                            <span>6</span>
                        </div>
                    </div>
                    <div class="icon-text">
                        <h5 class="mb2px">Rating</h5>
                        <p class="mb-0">User and Delivery rider rate themseleves.</p>
                    </div>
                </div>
            </div>
        </div>
        <!--feature new style end-->
    </div>
</div>
<!--features section end-->