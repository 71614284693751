<!--hero section start-->
<section class="hero-section full-screen gray-light-bg">
    <div class="container-fluid">
        <div class="row align-items-center justify-content-center">

           
            <div class="col-12 col-md-9 col-lg-9 col-xl-9 px-lg-6 my-5">
                <div class="login-signup-wrap px-4 px-lg-5 my-5">
                    <!-- Heading -->
                    <h1 class="text-center mb-1">
                        Merchant
                    </h1>

                    <!-- Subheading -->
                    <p class="text-muted text-center mb-5">
                        I am a logistics company and I will provide pickup/dropoff services.
                    </p>

                    <!-- Form -->
                    <form class="login-signup-form">
                        <div class="row">
                            <div class="col-md-6">
                                    <!-- begin name -->
                                    <div class="form-group">
                                        <!-- Label -->
                                        <label class="pb-1">
                                            Name of delivery merchant company
                                        </label>
                                        <!-- Input group -->
                                        <div class="input-group input-group-merge">
                                            <div class="input-icon">
                                                <span class="ti-user color-primary"></span>
                                            </div>
                                            <input type="text" class="form-control" name="name" id="name" placeholder="Enter your name">
                                        </div>
                                    </div>
                                    <!-- end name -->
                                    <!-- email -->
                                    <div class="form-group">
                                        <!-- Label -->
                                        <label class="pb-1">
                                            Email Address
                                        </label>
                                        <!-- Input group -->
                                        <div class="input-group input-group-merge">
                                            <div class="input-icon">
                                                <span class="ti-email color-primary"></span>
                                            </div>
                                            <input type="email" class="form-control" name="email" id="email" placeholder="name@address.com">
                                        </div>
                                    </div>
                                    <!-- end email -->
                                    <!-- hour -->
                                    <div class="form-group">
                                        <!-- Label -->
                                        <label class="pb-1">
                                            Hours of operation
                                        </label>
                                        <!-- Input group -->
                                            <div class="input-group input-group-merge">
                                                <div class="input-icon">
                                                    <span class="ti-time color-primary"></span>
                                                </div>
                                                <textarea class="form-control" id="hours" name="hours" rows="3"></textarea>                                      
                                            </div>
                                        </div>
                                    <!-- end hour -->

                                    <!-- bikes -->
                                    <div class="form-group">
                                        <!-- Label -->
                                        <label class="pb-1">
                                            How many delivery bikes do you have?
                                        </label>
                                        <!-- Input group -->
                                        <div class="input-group input-group-merge">
                                            <div class="input-icon">
                                                <span class="ti-truck color-primary"></span>
                                            </div>
                                            <textarea class="form-control" id="bikes" name="bikes" rows="3"></textarea>                                      
                                        </div>
                                    </div>
                                    <!-- end bikes -->

                                    <!-- smartphone -->
                                    <div class="form-group">
                                        <!-- Label -->
                                        <label class="pb-1">
                                            Are you willing to invest in Smart phones for your delivery riders?
                                        </label>
                                        <!-- Input group -->
                                        <div class="input-group input-group-merge">
                                            <div class="input-icon">
                                                <span class="ti-mobile color-primary"></span>
                                            </div>
                                            <input type="text" class="form-control" name="smartphone" id="smartphone" placeholder="yes">                                     
                                        </div>
                                    </div>
                                    <!-- end smartphone -->

                                </div>
                                <!-- end col-left ---------------------------------->

                                
                        

                                <!-- col right ------------------------------------->
                                    <div class="col-md-6">
                                    <!-- address -->
                                    <div class="form-group">
                                        <!-- Label -->
                                        <label class="pb-1">
                                            Business Address
                                        </label>
                                        <!-- Input group -->
                                        <div class="input-group input-group-merge">
                                            <div class="input-icon">
                                                <span class="ti-location-arrow color-primary"></span>
                                            </div>
                                            <textarea class="form-control" id="address" name="address" rows="3"></textarea>                                      
                                        </div>
                                    </div>
                                    <!-- end address -->
                                    <!-- phone -->
                                    <div class="form-group">
                                        <!-- Label -->
                                        <label class="pb-1">
                                            Phone Number
                                        </label>
                                        <!-- Input group -->
                                        <div class="input-group input-group-merge">
                                            <div class="input-icon">
                                                <span class="ti-headphone color-primary"></span>
                                            </div>
                                            <input type="number" class="form-control" name="phone" id="phone" placeholder="+234 811 111 3056">
                                        </div>
                                    </div>
                                    <!-- end phone -->

                                    <!-- gain -->
                                    <div class="form-group">
                                        <!-- Label -->
                                        <label class="pb-1">
                                        Are you interested in gaining more customers for your business through the Trackhub platform for a commission?
                                        </label>
                                        <!-- Input group -->
                                        <div class="input-group input-group-merge">
                                            <div class="input-icon">
                                                <span class="ti-lock color-primary"></span>
                                            </div>
                                            <input type="text" class="form-control" name="gain" id="gain" placeholder="yes">                                    
                                        </div>
                                    </div>
                                    <!-- end gain -->

                                    <!-- agreement -->
                                    <div class="form-group">
                                        <!-- Label -->
                                        <label class="pb-1">
                                        Are you willing to enter into a binding agreement for this?
                                        </label>
                                        <!-- Input group -->
                                        <div class="input-group input-group-merge">
                                            <div class="input-icon">
                                                <span class="ti-pencil color-primary"></span>
                                            </div>
                                            <input type="text" class="form-control" name="agreement" id="agreement" placeholder="yes">                                     
                                        </div>
                                    </div>
                                    <!-- end agreement -->

                                    <!-- data -->
                                    <div class="form-group">
                                        <!-- Label -->
                                        <label class="pb-1">
                                            Are you willing to provide data and call credit to your delivery riders?
                                        </label>
                                        <!-- Input group -->
                                        <div class="input-group input-group-merge">
                                            <div class="input-icon">
                                                <span class="ti-lock color-primary"></span>
                                            </div>
                                            <input type="text" class="form-control" name="data" id="data" placeholder="yes">                                     
                                        </div>
                                    </div>
                                    <!-- end data -->

                            </div>
                            <!-- end col right --------------------------------------------->
                        </div>
                        
                       

                         <!-- admin -->
                         <div class="form-group">
                            <!-- Label -->
                            <label class="pb-1">
                                Do you have a dedicated admin to monitor transactions at the backend?
                            </label>
                            <!-- Input group -->
                            <div class="input-group input-group-merge">
                                <div class="input-icon">
                                    <span class="ti-comments-smiley color-primary"></span>
                                </div>
                                <textarea class="form-control" id="w3review" name="w3review" rows="3"></textarea>

                                      
                            </div>
                        </div>

                        <!-- end admin -->

                        <div class="form-check d-flex align-items-center text-center">
                            <input type="checkbox" class="form-check-input mt-0 mr-3" id="exampleCheck1">
                            <label class="form-check-label small" for="exampleCheck1">I agree your <a routerLink="/terms-and-conditions">terms and conditions</a></label>
                        </div>

                        <!-- Submit -->
                        <button class="btn btn-lg btn-block solid-btn border-radius mt-4 mb-3">
                            Sign up
                        </button>

                        <!-- Link -->
                        <div class="text-center">
                            <small class="text-muted text-center">
                                Already have an account? <a href="http://admin.trackhub.ng/" target="_blank">Log in</a>.
                            </small>
                        </div>

                    </form>
                </div>
            </div>
        </div> <!-- / .row -->
    </div>
</section>
<!--hero section end-->