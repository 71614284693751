<!--promo section start-->
<section class="promo-section ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-9">
                <div class="section-heading mb-5">
                    <h2>Frequently Asked Questions</h2>
                </div>
            </div>
        </div>
        <!--pricing faq start-->
        <div class="row">
            <div class="col-lg-6">
                <div id="accordion-1" class="accordion accordion-faq">
                    <!-- Accordion card 1 -->
                    <div class="card">
                        <div class="card-header py-4" id="heading-1-1" data-toggle="collapse" role="button"
                             data-target="#collapse-1-1" aria-expanded="false" aria-controls="collapse-1-1">
                            <h6 class="mb-0"><span class="ti-receipt mr-3"></span> How does Trackhub make money?</h6>
                        </div>
                        <div id="collapse-1-1" class="collapse" aria-labelledby="heading-1-1"
                             data-parent="#accordion-1">
                            <div class="card-body">
                                <p>Trackhub charges a 6.5-7.5% commission per delivery processed on the platform.</p>
                            </div>
                        </div>
                    </div>
                    <!-- Accordion card 2 -->
                    <div class="card">
                        <div class="card-header py-4" id="heading-1-2" data-toggle="collapse" role="button"
                             data-target="#collapse-1-2" aria-expanded="false" aria-controls="collapse-1-2">
                            <h6 class="mb-0"><span class="ti-receipt mr-3"></span> Will the apps require smart phones for delivery riders?</h6>
                        </div>
                        <div id="collapse-1-2" class="collapse" aria-labelledby="heading-1-2"
                             data-parent="#accordion-1">
                            <div class="card-body">
                                <p>Yes, the delivery merchants would have to provide smart phones for their riders in order to access the rider app.</p>
                            </div>
                        </div>
                    </div>
                    <!-- Accordion card 3 -->
                    <div class="card">
                        <div class="card-header py-4" id="heading-1-3" data-toggle="collapse" role="button"
                             data-target="#collapse-1-3" aria-expanded="false" aria-controls="collapse-1-3">
                            <h6 class="mb-0"><span class="ti-receipt mr-3"></span> What is the difference between Trackhub and other aggregators?
                            </h6>
                        </div>
                        <div id="collapse-1-3" class="collapse" aria-labelledby="heading-1-3"
                             data-parent="#accordion-1">
                            <div class="card-body">
                                <p>Trackhub only provides the technology to increase access to customers and organize your operations. You retain ownership over your bikes and your brand. Once your delivery company is signed up, we will provide a sticker to show your brand affiliation. </p>
                            </div>
                        </div>
                    </div>
                     <!-- Accordion card 4 -->
                     <div class="card">
                        <div class="card-header py-4" id="heading-1-4" data-toggle="collapse" role="button"
                             data-target="#collapse-1-4" aria-expanded="false" aria-controls="collapse-1-4">
                            <h6 class="mb-0"><span class="ti-receipt mr-3"></span> How will the platform reconcile transactions?
                            </h6>
                        </div>
                        <div id="collapse-1-4" class="collapse" aria-labelledby="heading-1-4"
                             data-parent="#accordion-1">
                            <div class="card-body">
                                <p>For cash transactions (including transfer, POS), the platform records when fees are paid to the delivery rider and the commission due to Trackhub. Card transactions are enabled by Paystack on our platform. These transactions pass through Trackhub which remits to the delivery merchant the amount due to them after deducting commissions owed from cash transactions. We have a wallet feature that allows delivery merchants withdraw amounts due them by Trackhub. As an alternative measure, we have signed agreements with delivery merchants to collect amounts owed to Trackhub on a weekly basis.</p>
                            </div>
                        </div>
                    </div>
                     <!-- Accordion card 5 -->
                     <div class="card">
                        <div class="card-header py-4" id="heading-1-5" data-toggle="collapse" role="button"
                             data-target="#collapse-1-5" aria-expanded="false" aria-controls="collapse-1-5">
                            <h6 class="mb-0"><span class="ti-receipt mr-3"></span> Does the platform only serve delivery companies who do not have apps?
                            </h6>
                        </div>
                        <div id="collapse-1-5" class="collapse" aria-labelledby="heading-1-5"
                             data-parent="#accordion-1">
                            <div class="card-body">
                                <p>Yes, these are our primary market.</p>
                            </div>
                        </div>
                    </div>
                    <!-- Accordion card 6 -->
                    <div class="card">
                        <div class="card-header py-4" id="heading-1-6" data-toggle="collapse" role="button"
                             data-target="#collapse-1-6" aria-expanded="false" aria-controls="collapse-1-6">
                            <h6 class="mb-0"><span class="ti-receipt mr-3"></span> Does the app exist on android and IOS?
                            </h6>
                        </div>
                        <div id="collapse-1-6" class="collapse" aria-labelledby="heading-1-6"
                             data-parent="#accordion-1">
                            <div class="card-body">
                                <p>For now, only the android app exists, an IOS version is in the works.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div id="accordion-2" class="accordion accordion-faq">
                    <!-- Accordion card 1 -->
                    <!-- <div class="card">
                        <div class="card-header py-4" id="heading-2-1" data-toggle="collapse" role="button"
                             data-target="#collapse-2-1" aria-expanded="false" aria-controls="collapse-2-1">
                            <h6 class="mb-0"><span class="ti-receipt mr-3"></span> Which license do I need?</h6>
                        </div>
                        <div id="collapse-2-1" class="collapse" aria-labelledby="heading-2-1"
                             data-parent="#accordion-2">
                            <div class="card-body">
                                <p>Distinctively recaptiualize customer directed channels before installed base communities. Continually disintermediate distinctive web services vis-a-vis team building e-commerce.</p>
                            </div>
                        </div>
                    </div> -->
                    <!-- Accordion card 2 -->
                    <div class="card">
                        <div class="card-header py-4" id="heading-2-2" data-toggle="collapse" role="button"
                             data-target="#collapse-2-2" aria-expanded="false" aria-controls="collapse-2-2">
                            <h6 class="mb-0"><span class="ti-receipt mr-3"></span> What payment methods would Trackhub accept?</h6>
                        </div>
                        <div id="collapse-2-2" class="collapse" aria-labelledby="heading-2-2"
                             data-parent="#accordion-2">
                            <div class="card-body">
                                <p>We accept both cash payments and card payments.</p>
                            </div>
                        </div>
                    </div>
                    <!-- Accordion card 3 -->
                    <div class="card">
                        <div class="card-header py-4" id="heading-2-3" data-toggle="collapse" role="button"
                             data-target="#collapse-2-3" aria-expanded="false" aria-controls="collapse-2-3">
                            <h6 class="mb-0"><span class="ti-receipt mr-3"></span> Does the platform track bikes?
                            </h6>
                        </div>
                        <div id="collapse-2-3" class="collapse" aria-labelledby="heading-2-3"
                             data-parent="#accordion-2">
                            <div class="card-body">
                                <p>We do not have hardware trackers installed on bikes, instead we use GPS tracking via smartphones to enable rider visibility on the platform.</p>
                            </div>
                        </div>
                    </div>
                     <!-- Accordion card 4 -->
                     <div class="card">
                        <div class="card-header py-4" id="heading-2-4" data-toggle="collapse" role="button"
                             data-target="#collapse-2-4" aria-expanded="false" aria-controls="collapse-2-4">
                            <h6 class="mb-0"><span class="ti-receipt mr-3"></span> Apart from the user app what other product features does Trackhub have?
                            </h6>
                        </div>
                        <div id="collapse-2-4" class="collapse" aria-labelledby="heading-2-4"
                             data-parent="#accordion-2">
                            <div class="card-body">
                                <p>Apart from the user and rider apps, we also have a web based administrative module that consists of: Wallet, Payments reconciliation, rider set up, expenses monitoring and visibility view.</p>
                            </div>
                        </div>
                    </div>
                    <!-- Accordion card 5 -->
                    <div class="card">
                        <div class="card-header py-4" id="heading-2-5" data-toggle="collapse" role="button"
                             data-target="#collapse-2-5" aria-expanded="false" aria-controls="collapse-2-5">
                            <h6 class="mb-0"><span class="ti-receipt mr-3"></span> Why can’t we extend this to food?
                            </h6>
                        </div>
                        <div id="collapse-2-5" class="collapse" aria-labelledby="heading-2-5"
                             data-parent="#accordion-2">
                            <div class="card-body">
                                <p>A food app is in the pipeline.</p>
                            </div>
                        </div>
                    </div>
                     <!-- Accordion card 6 -->
                     <div class="card">
                        <div class="card-header py-6" id="heading-2-6" data-toggle="collapse" role="button"
                             data-target="#collapse-2-6" aria-expanded="false" aria-controls="collapse-2-6">
                            <h6 class="mb-0"><span class="ti-receipt mr-3"></span> How does the platform calculate rates?
                            </h6>
                        </div>
                        <div id="collapse-2-6" class="collapse" aria-labelledby="heading-2-6"
                             data-parent="#accordion-2">
                            <div class="card-body">
                                <p>Our LOCUS algorithm (Logistics, Curation, System) computes delivery rates as follows: N300 base fee + N40 per km)</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--pricing faq end-->
    </div>
</section>
<!--promo section end-->