 <!--our video promo section start-->
 <section id="download" class="video-promo ptb-100">
    <div class="container">
        <div class="row align-items-center justify-content-between">
            <div class="col-md-12">
                <div class="download-content">
                    <h2>Introduction</h2>
                    <p>Trackhub understands the importance of your privacy and is committed to protecting your personal data as you interact with our website and mobile application. We have set below, our Privacy Policy that explains how we collect, use, disclose, transfer and store the personal information you make available to us.</p>
                     <h2>Scope</h2>
                    <p>Trackhub collects information about you when you use our mobile applications, websites, and other online products and services and through other interactions and communications you have with us. This Privacy Policy applies to all users of Trackhub.ng services, both on our website and our mobile application. And shall unless covered by a separate privacy policy, apply to:</p>

                    <ul class="list-unstyled">
                        <li class="py-2">
                            <div class="d-flex align-items-center">
                                <div>
                                    <div class="badge badge-circle badge-primary mr-3">
                                        <span class="ti-check"></span>
                                    </div>
                                </div>
                                <div><p class="mb-0">Customers: Individuals or entities who request the use of the app for scheduled and on-demand courier and food delivery services.</p></div>
                            </div>
                        </li>
                        <li class="py-2">
                            <div class="d-flex align-items-center">
                                <div>
                                    <div class="badge badge-circle badge-primary mr-3">
                                        <span class="ti-check"></span>
                                    </div>
                                </div>
                                <div><p class="mb-0">Delivery Merchants:  Logistic companies who provide, scheduled and on-demand courier services using motor bikes across cities in Nigeria.</p></div>
                            </div>
                        </li>
                        <li class="py-2">
                            <div class="d-flex align-items-center">
                                <div>
                                    <div class="badge badge-circle badge-primary mr-3">
                                        <span class="ti-check"></span>
                                    </div>
                                </div>
                                <div><p class="mb-0">Riders: entities who are employed by delivery merchants to deliver courier services using delivery merchant motor bikes across cities in Nigeria.</p></div>
                            </div>
                        </li>
                        <li class="py-2">
                            <div class="d-flex align-items-center">
                                <div>
                                    <div class="badge badge-circle badge-primary mr-3">
                                        <span class="ti-check"></span>
                                    </div>
                                </div>
                                <div><p class="mb-0">Restaurants: entities and outlets that prepare and sell food to customers on an eat-in and take-out basis</p></div>
                            </div>
                        </li>
                        <li class="py-2">
                            <div class="d-flex align-items-center">
                                <div>
                                    <div class="badge badge-circle badge-primary mr-3">
                                        <span class="ti-check"></span>
                                    </div>
                                </div>
                                <div><p class="mb-0">Restaurants: entities and outlets that prepare and sell food to customers on an eat-in and take-out basis</p></div>
                            </div>
                        </li>
                    </ul>
                    <p>This Statement also applies to information we collect from or about customers, delivery merchants, riders, couriers, restaurants, or any other persons who use the Trackhub platform under license. If you interact with the Services as both a customer and a rider, the respective privacy statements apply to your different interactions. This notice also covers other collection of personal data in connection with services provided by Trackhub</p>
                    <h3>Topics</h3>
                    <ul>
                        <li><span class="ti-check"></span> What data do we collect?</li>
                        <li><span class="ti-check"></span> How do we collect your data?</li>
                        <li><span class="ti-check"></span> How will we use your data?</li>
                        <li><span class="ti-check"></span> How do we store your data?</li>
                        <li><span class="ti-check"></span> What are your data protection rights?</li>
                        <li><span class="ti-check"></span> What are cookies?</li>
                        <li><span class="ti-check"></span> How do we use cookies?</li>
                        <li><span class="ti-check"></span> What types of cookies do we use?</li>
                        <li><span class="ti-check"></span> How to manage your cookies?</li>
                        <li><span class="ti-check"></span> Marketing</li>
                        <li><span class="ti-check"></span> Privacy Policies of other websites and apps</li>
                        <li><span class="ti-check"></span> Changes to our privacy policy</li>
                        <li><span class="ti-check"></span> How to contact us</li>
                    </ul>
                    <h3>What data do we collect?</h3>
                    <p>Trackhub.ng collects the following data during the use of our services through this website and the mobile application:</p>
                    <ul>
                        <li><span class="ti-check"></span> Personal Identification Information such as your; name, email address, phone number, address, date of birth, login name, password, profile picture government identification documents (including driver’s licenses, numbers and images, signature and photo, vehicle or insurance information of drivers and delivery partners and user settings).</li>
                        <li><span class="ti-check"></span> Payment or Banking information such as Bank accounts, Credit card information, Debit card information including related payment verification information.</li>
                        <li><span class="ti-check"></span> Device data: We may collect data relating to the device being used to access our services as; device IP address, hardware model, operating system, version, software, unique device identifiers, advertising identifiers, serial numbers, mobile network data and Web browsing data.</li>
                        <li><span class="ti-check"></span> Demographic Data: We may collect demographic data about users, including through user surveys or from third parties.</li>
                        <li><span class="ti-check"></span> User content: We collect the information users submit when they contact Trackhub.ng customer support, provide ratings or compliments for other users or partners, or otherwise contact Trackhub.ng. This may include feedback, photographs or other recordings collected by users.</li>
                        <li><span class="ti-check"></span> Location data: We collect precise or approximate location data from a user’s mobile device if enabled by the user to do so. For riders and delivery merchants, Trackhub collects this data when the Trackhub app is running in the foreground (app open and on-screen) or background (app open but not on-screen) of their mobile device or web application. This also applies to customers and restaurants</li>
                        <li><span class="ti-check"></span> Usage data: We collect data about how users interact with our services via our website and mobile application. This includes data such as access dates and times, app usage, app features or pages viewed, app crashes and other system activity, type of browser, and third-party sites or services used before interacting with our services.</li>
                        <li><span class="ti-check"></span> Cookies, pixels, tags, and similar tracking technologies that create and maintain unique identifiers.</li>
                        <li><span class="ti-check"></span> Data from Others: We may also collect personal information from third party providers of marketing lists, updated delivery address information from page view information form merchants and through other methods.</li>
                    </ul>
                    <h3>How do we collect your data?</h3>
                    <p>You directly provide personal data to us when you:</p>
                    <ul>
                        <li><span class="ti-check"></span> Register online and create a user profile on the website or the mobile application.</li>
                        <li><span class="ti-check"></span> Voluntarily complete a customer survey or provide feedback on any of our message boards or via email.</li>
                        <li><span class="ti-check"></span> Use or view our website via your browsers cookies.</li>
                    </ul>
                    <p>We only collect and use personal data where we have lawful grounds to do so. Thus the basis for processing user personal data is to provide requested services and features, for the purposes of Trackhub’s legitimate interests or those of other parties, to fulfill our legal obligations, or based on consent.</p>
                    <h3>How do we use your data?</h3>
                    <p>The personal data we collect, helps us personalize the website and mobile application and curate the platform in accordance with user preferences. The data collected helps us to confirm the identity of users for security purposes, processing of orders, delivery of products and services, processing payments, communication with the user data, detection and prevention of fraud, prevention of hacking, bugs and abuse of the website and mobile application.</p>
                    <p>We may use the photos submitted by riders, merchants and/or delivery partners to verify their identities, through facial recognition technologies.</p>
                    <p>We do not sell any of your personal data. However, to enable us to provide our services to you and to run our business, we share data with third parties from the following categories:</p>
                    <ul>
                        <li><span class="ti-check"></span> Third parties involved in the processing of payments e.g. Flutterwave; Marketing analytics platforms E.g google analytics, mix panel. etc.</li>
                        <li><span class="ti-check"></span> Law enforcement and other government authorities.</li>
                        <li><span class="ti-check"></span> Third Parties who provide professional services to us e.g. advertising partners, marketing agencies, web hosting providers, business consultants, etc.</li>
                    </ul>
                    <h3>How do we store your data?</h3>
                    <p>Trackhub carefully stores your personal data during transmission by using Secure Sockets Layer (SSL) software which safely encrypts the information you input on this website. The platform doesn’t store user payment information; this is stored on the platform of our payment partner -Flutterwave.  We retain user profile, transaction, personal data and other information for as long as a user maintains their Trackhub account. Trackhub may retain certain user data after receiving an account deletion request if necessary, such as to comply with legal requirements.</p>
                    <p>For merchants, riders and delivery partners, we also retain vehicle and background check information (to the extent permitted by law) for as long as they maintain their Trackhub account.</p>
                    <p>Customers may deactivate their account through the Settings menu in the Trackhub app. Delivery merchant accounts may be deactivated by the Trackhub Superadmin upon request by the delivery merchant.  Following such requests, Trackhub deletes the data that it is not required to retain for purposes of regulatory, tax, insurance, litigation, or other legal requirements.</p>
                    <p>Trackhub shall retain location, device, and usage data for these purposes for a minimum of 7 years; and may also use it for the purposes of safety, security, fraud prevention and detection, and research and development while it retains such data. In certain circumstances, Trackhub may be unable to deactivate a user’s account, such as if there’s an outstanding credit on the account or an unresolved claim or dispute. Upon resolution of the issue preventing deletion, Trackhub will deactivate the account as described above.</p>
                    <p>If Trackhub deactivates a user’s account because of unsafe, untoward behavior or security breaches, Trackhub may retain certain information about that account to prevent that user from opening a new Trackhub account in the future.</p>
                    <h3>What are your data protection rights?</h3>
                    <ul>
                        <li><span class="ti-check"></span> The right to access: You have the right to request for copies of your personal data from us. We may however charge you a small fee for this service.</li>
                        <li><span class="ti-check"></span> The right to Rectification:  You have a right to request that we correct any information you believe is inaccurate or complete information you believe is incomplete.</li>
                        <li><span class="ti-check"></span> The right to erasure: You have the right to request that our company erase your personal data, under certain conditions.</li>
                        <li><span class="ti-check"></span> The right to restrict processing: You have the right to request that we restrict processing your personal data under certain conditions.</li>
                        <li><span class="ti-check"></span> The right to object to processing: You have a right to object to our processing of your personal data, under certain conditions.</li>
                        <li>T<span class="ti-check"></span> he right to data portability: You have a right to request that we transfer the data we have collected from you to another organization, or directly to you under certain conditions.</li>
                    </ul>
                    <p>If you would like to exercise any one of these rights, please contact us via phone or email provided in (n) below. Kindly note that if you make a request, we have one month to respond to you.</p>
                    <h3>What is a cookie?</h3>
                    <p>A cookie is a file containing an identifier (a string of numbers and letters) that are transferred to your device to collect standard internet log information and visitor behavior information to enable our systems recognize your device and provide features that are tailored to your preferences in order to provide an enhanced user experience for you.</p>
                    <p>Cookies do not necessarily contain any information that personally identifies a user, but personal data that we store about you may be linked to the information stored in and obtained from cookies.</p>
                    <h3>How do we use cookies?</h3>
                    <p>When you visit our website, we may collect information from you automatically through cookies or similar technology. Note however, that acceptance of cookies is not a requirement for visiting or navigating the website. Trackhub uses cookies in a range of ways to improve your experience on our website, including: keeping you signed in, understanding how you use the website, authenticating users, remembering user preferences and settings, delivering and measuring the effectiveness of advertising campaigns, analyzing site traffic and trends, to prevent fraudulent use of login credentials etc.</p>
                    <h3>What types of cookies do we use?</h3>
                    <p>Cookies may either be ‘session’ cookies, ‘persistent’ cookies or ‘provenance’ cookies; however, our website uses cookies for the following purposes:</p>
                    <ul>
                        <li><span class="ti-check"></span> Functionality: these cookies enable us recognize you on our website and remember your previously selected preferences such as your language preference and location. In order to achieve this, a mix of first-party and third-party cookies are used.</li>
                        <li><span class="ti-check"></span> Advertising: we use these cookies to collect information about your visit to this website, the content you viewed, the links you followed and information about your browser, device, and your IP address. We may sometimes share some limited data collected through cookies with our advertising partners. This means that when you visit another website, you may be shown adverts based on your browsing patterns on our website.</li>
                        
                    </ul>
                    <h3>How to manage your cookies?</h3>
                    <p>You may set your browser not to accept cookies from this website, however, in few cases, some of our website features may not function as a result.</p>
                    <h3>
                        Marketing
                    </h3>
                    <p>Trackhub uses Google Analytics and Mixpanel for marketing and personal data optimization purposes.</p>
                    <h3>Privacy Policies of other websites</h3>
                    <p>Trackhub.ng may contain links to other websites. Our Privacy Policy only applies to this website and the mobile application; if you click on a link to another website, you should read their privacy policy.</p>
                    <h3>Choice and Transparency</h3>
                    <p>Trackhub enables users to access and control the data that Trackhub collects, through the following means:</p>
                    <ul>
                        <li><span class="ti-check"></span> In-app settings</li>
                        <li><span class="ti-check"></span> Device permissions</li>
                        <li><span class="ti-check"></span> In-app ratings pages</li>
                        <li><span class="ti-check"></span> Marketing opt-outs</li>
                    </ul>
                    <p>Trackhub also enables users to request access to or copies of their data, changes or updates to their accounts, deletion of their accounts, or that Trackhub restrict its processing of user personal data.</p>
                    <h3>Changes to our privacy policy</h3>
                    <p>Trackhub keeps its privacy policy under regular review and places updated on this web page. This Privacy Policy was last updated on January 1, 2020. Use of our services after an update constitutes consent to the updated Privacy Policy to the extent permitted by law.</p>
                    <h3>How to Contact Us</h3>
                    <p>The data controller responsible for your personal information for the purposes applicable to the Nigerian Data Protection Regulation and the European Union Data Protection Law is:</p>
                    <p>Trackhub Ventures Limited</p>
                    <p>Suite B18, Busy Mart Plaza, 44 Ebitu Ekiwe Street Jabi, Abuja, Nigeria</p>
                    <p>Telephone: +2347036848035</p>
                    <p>Email: trackhubng@gmail.com</p>
                    <p>If you have any questions about this privacy policy or our data practices, please contact us at the address, telephone number or email listed above and specify your country of residence and the nature of your question.</p>
                </div>
            </div>
            
        </div>
    </div>
</section>
<!--our video promo section end-->