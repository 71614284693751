<!--shape image start-->
<div class="shape-img subscribe-wrap">
    <img src="assets/img/footer-top-shape.png" alt="footer shape" class="img-fluid">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <form action="#" method="" class="subscribe-form subscribe-form-footer d-none d-md-block d-lg-block">
                    <div class="d-flex align-items-center">
                        <input type="text" class="form-control input" id="email-footer" name="email"
                               placeholder="Subcribe to latest update">
                        <input type="submit" class="button btn solid-btn" id="submit-footer" value="Subscribe">
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!--shape image end-->

<!--footer section start-->
<footer class="footer-section">

    <!--footer top start-->
    <div class="footer-top pt-150 pb-5 background-img-2"
         style="background: url('assets/img/footer-bg.png')no-repeat center top / cover">
        <div class="container">
            <div class="row justify-content-between">
                <div class="col-lg-4 mb-3 mb-lg-0">
                    <div class="footer-nav-wrap text-white">
                        <img src="assets/img/logo2.png" alt="footer logo" width="180px" class="img-fluid mb-3">
                        <p>See below where you can find us.</p>

                        <div class="social-list-wrap">
                            <ul class="social-list list-inline list-unstyled">
                                <li class="list-inline-item"><a href="https://www.facebook.com/groups/475587016194267" target="_blank" title="Facebook"><span
                                        class="ti-facebook"></span></a></li>
                                <li class="list-inline-item"><a href="https://www.youtube.com/channel/UCmdOFOD1I6gZCVad6d09Pyg" target="_blank" title="Youtube"><span
                                        class="ti-youtube"></span></a></li>
                                <li class="list-inline-item"><a href="https://www.instagram.com/spanglermarket/" target="_blank"
                                                                title="Instagram"><span class="ti-instagram"></span></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 ml-auto mb-4 mb-lg-0">
                    <div class="footer-nav-wrap text-white">
                        <h5 class="mb-3 text-white">Useful Links</h5>
                        <ul class="list-unstyled">
                            <li class="mb-2"><a href="/">Terms and Conditions</a></li>
                            <li class="mb-2"><a href="/">Privacy Policy</a></li>
                            <li class="mb-2"><a href="/">Reputation and Trust</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 ml-auto mb-4 mb-lg-0">
                    <div class="footer-nav-wrap text-white">
                        <h5 class="mb-3 text-white">Get In Touch</h5>
                        <ul class="list-unstyled support-list">
                            <li class="mb-2 d-flex align-items-center"><span class="ti-location-pin mr-2"></span>
                               Plot 323, Agulu Street, 
                            <br>Dawaki, FCT Abuja.
                            </li>
                            <li class="mb-2 d-flex align-items-center"><span class="ti-mobile mr-2"></span> <a
                                    href="tel:+2348063684073"> 0806 368 4073, 0803 276 0525, 0814 259 7752</a></li>
                            <li class="mb-2 d-flex align-items-center"><span class="ti-email mr-2"></span><a
                                    href="mailto:info@spanglermarket.com"> info@spanglermarket.com</a></li>
                        </ul>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <!--footer top end-->

    <!--footer copyright start-->
    <div class="footer-bottom gray-light-bg pt-4 pb-4">
        <div class="container">
            <div class="row text-center justify-content-center">
                <div class="col-md-7 col-lg-7 col-sm-12"><p class="copyright-text pb-0 mb-0">Copyright ©2021 Spangler Market. All Rights Reserved.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <!--footer copyright end-->
</footer>
<!--footer section end-->