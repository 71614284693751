import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reputation-and-trust',
  templateUrl: './reputation-and-trust.component.html',
  styleUrls: ['./reputation-and-trust.component.scss']
})
export class ReputationAndTrustComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
