<!--contact us section start-->
<section id="contact" class="contact-us ptb-100 gray-light-bg">
    <div class="container">
        <div class="row">
            <div class="col-12 pb-3 message-box d-none">
                <div class="alert alert-danger"></div>
            </div>
            <div class="col-md-5">
                <div class="section-heading">
                    <h3>Contact Us</h3>
                    <p>It's very easy to get in touch with us. Just use the contact form or pay us a
                        visit at the office.</p>
                </div>
                <div class="footer-address">
                    <h6><strong>Head Office</strong></h6>
                    <p>Suite B18, BusyMart Plaza,
                        44 Ebitu Ukiwe Street, Jabi Abuja.</p>
                    <ul>
                        <li><span>Phone: +234 811 111 3056</span></li>
                        <li><span>Email : <a href="mailto:info@trackhub.ng">info@trackhub.ng</a></span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-7">
               

                <form method="POST" action="https://trackhub.ng/contact.php" class="contact-us-form" novalidate="novalidate">
                    <h5>Reach us quickly</h5>
                    <div class="row">
                        <div class="col-sm-6 col-12">
                            <div class="form-group">
                                <input type="text" class="form-control" name="name" id="name" placeholder="Enter name"
                                        required="required">
                            </div>
                        </div>
                        <div class="col-sm-6 col-12">
                            <div class="form-group">
                                <input type="email" class="form-control" name="email" id="email" placeholder="Enter email"
                                        required="required">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-12">
                            <div class="form-group">
                                <input type="text" name="phone" class="form-control" id="phone"
                                        placeholder="Your Phone">
                            </div>
                        </div>
                        <div class="col-sm-6 col-12">
                            <div class="form-group">
                                <input type="text" name="company" size="40" class="form-control"
                                        id="company" placeholder="Your Company">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <textarea name="message" id="message" class="form-control" rows="7" cols="25"
                                            placeholder="Message"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 mt-3">
                            <button type="submit" class="btn-red scrool" id="btnContactUs">
                                Send Message
                            </button>
                        </div>
                    </div>
                </form>
                <p class="form-message"></p>
            </div>
        </div>
    </div>
</section>
<!--contact us section end-->