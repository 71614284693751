import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { NewLayoutsComponent } from './layouts/new-layouts/new-layouts.component';
import { TlayoutComponent } from './layouts/tlayout/tlayout.component';
import { MlayoutComponent } from './layouts/mlayout/mlayout.component';
import { FaqComponent } from './pages/faq/faq.component';
import { AboutComponent } from './pages/about/about.component';
import { SlayoutComponent } from './layouts/slayout/slayout.component';
import { HowItWorksComponent } from './pages/how-it-works/how-it-works.component';
import { ContactComponent } from './pages/contact/contact.component';
import { MerchantComponent } from './pages/merchant/merchant.component';
import { ReputationAndTrustComponent } from './pages/reputation-and-trust/reputation-and-trust.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { ThankYouComponent } from './pages/thank-you/thank-you.component';
import { AdsComponent } from './pages/ads/ads.component';
import { SuccessComponent } from './pages/success/success.component';


const routes: Routes = [
  {
    path: '',
    component: NewLayoutsComponent, 
    // canActivate: [AuthGuard],
    children: [
        { path: '', component: DashboardComponent, data: { breadcrumb: 'Spangler Market - creating a better future for entrepreneurs'} },
        { path: 'dashboard', component: DashboardComponent, data: { breadcrumb: 'Spangler Market - creating a better future for entrepreneurs'} },
        
    ],
    
},
{
  path: '',
  component: MlayoutComponent, 
  children: [
    { path: 'merchant', component: MerchantComponent, data: { breadcrumb: 'Become a Merchant'} },
    { path: 'contact', component: ContactComponent, data: { breadcrumb: 'Contact Us'} },
  ],
  
},

{
  path: '',
  component: TlayoutComponent, 
  children: [
    { path: 'thank-you', component: ThankYouComponent, data: { breadcrumb: 'Thank You'} },
    { path: 'success', component: SuccessComponent, data: { breadcrumb: 'Payment Successful'} },
  ],
  
},

{
  path: '',
  component: SlayoutComponent, 
  children: [
    { path: 'faq', component: FaqComponent, data: { breadcrumb: 'FAQ'} },
    { path: 'about', component: AboutComponent, data: { breadcrumb: 'About Us'} },
    { path: 'how-it-works', component: HowItWorksComponent, data: { breadcrumb: 'How It Works'} },
    { path: 'ads', component: AdsComponent, data: { breadcrumb: 'Sponsored Ads'} },
    { path: 'terms-and-conditions', component: TermsAndConditionsComponent, data: { breadcrumb: 'Terms and Conditions'} },
    { path: 'privacy-policy', component: PrivacyPolicyComponent, data: { breadcrumb: 'Privacy Policy'} },
    { path: 'reputation-and-trust', component: ReputationAndTrustComponent, data: { breadcrumb: 'Reputation and Trust'} },
  ]
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
