 <!--our video promo section start-->
 <section id="download" class="video-promo ptb-100">
    <div class="container">
        <div class="row align-items-center justify-content-between">
            <div class="col-md-7">
                <div class="download-content">
                   
                    <p>These terms and conditions (“T&Cs”) apply to your access to and use of the Trackhub mobile and Trackhub web app (defined hereinbelow). The Application is operated by Trackhub Ventures Ltd. and its subsidiaries, licensees and affiliated companies. You acknowledge that Trackhub is providing you with a revocable license to use limited features of the Application and is not selling the Application or any features or technologies contained therein. By continuing to use the Application, you are consenting to be bound by these T&Cs. Please ensure that you read and understand all these T&Cs before you use the application and features contained therein. If you find any of the T&Cs unacceptable, do not accept to use the Application or any of its features. Your agreement to these T&Cs shall operate as a binding and legally enforceable agreement between you and Trackhub with respect to the features offered using the web and mobile application. </p>
                     <h2>Definitions</h2>
                    <p>All the items in these T&Cs will have the meaning assigned to them here below: “Application” shall mean the Trackhub web and mobile applications and shall mean and include any updates provided by Trackhub from time to time. “Account” refers to the User’s and Merchant’s accounts on the Trackhub web and mobile applications that enables the use of the Application by the Users and Merchants.</p>
                    <p>“Registration Data” shall mean and may include the present, valid, true and accurate name, email, age and other such information as may be required by Trackhub from time to time, provided by the Users and Merchants at the time of registration on the Trackhub web and mobile applications or website.</p>
                    <p>“User” shall mean the person or persons who have created an Account to request deliveries on the Application. “Merchant” shall mean delivery companies who have signed up on the application to accept, and process deliveries on the application. “Riders” shall mean bikes registered under delivery companies for the dispatch and delivery of items requested by “Users”.</p>
                    <p>“You”, “Your” or “Yourself” shall mean reference to the any of the stakeholders accessing the Application.</p>
                </div>
            </div>
            <div class="col-md-5">
                <div class="download-img mt-lg-5 mt-md-5 mt-sm-0">
                    <img src="../../../assets/img/image-14.png" alt="download" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</section>
<!--our video promo section end-->